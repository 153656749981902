"use client";

import { Avatar, Card, Divider } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useAuthContext, useChatContext } from "../context";
import ChatUserComponent from "./chat/chatUserComponent";
import { ChatInterface } from "../interfaces";
import { useEffect } from "react";
const LeftPanel = () => {
  const { user, setUser } = useAuthContext();
  const { chats } = useChatContext();
  const navigate = useNavigate();
  const logout = (e: any) => {
    window.FB.logout();
    //clean cache
    sessionStorage.clear();
    setUser(undefined);
    navigate("/");
  };
  useEffect(() => {
    console.log("chats", chats);
  }, [chats]);
  return (
    <Card className="h-[calc(100vh)] my-auto bg-white w-full max-w-[23rem] py-8 px-6 shadow-lg shadow-blue-gray-900/50 rounded-none index-0">
      <div className="flex gap-3 items-center">
        <Avatar
          isBordered
          size={"lg"}
          color="success"
          src="https://i.pravatar.cc/150?u=a04258114e29026302d"
        />
        <div className="flex flex-col items-start">
          <span className="text-small font-semibold text-primary">
            {user?.email ?? "-"}
          </span>
          <span className="text-tiny text-zinc-800">
            {user?.role === "cyt" ? "Agente de CYT" : "Otro"}
          </span>
          <div
            onClick={logout}
            className={"font-semibold text-xs text-danger flex items-center"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
              />
            </svg>{" "}
            <p>Salir</p>
          </div>
        </div>
      </div>
      <Divider className="my-4" />
      <p className={"text-lg font-semibold pb-3"}>Conversaciones</p>
      {chats!.length > 0 ? (
        chats!.map((chat: ChatInterface) => (
          <ChatUserComponent
            user={chat.consumer}
            lastMessage={chat.events[chat.events.length - 1].message ?? "..."}
            key={chat.consumer.providerId}
          />
        ))
      ) : (
        <div className="flex items-center h-full align-middle">
          <p className={"font-semibold text-center w-full"}>
            Sin conversaciones...
          </p>
        </div>
      )}
    </Card>
  );
};
export default LeftPanel;
