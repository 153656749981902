import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { ReactElement } from "react";

interface DefaultModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | undefined;
  size:
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "xs"
    | "3xl"
    | "4xl"
    | "5xl"
    | "full"
    | undefined;
  body: string;
  actions: ReactElement;
}

export const DefaultModal = ({
  title,
  isOpen,
  onClose,
  size,
  body,
  actions,
}: DefaultModalProps) => {
  return (
    <Modal size={size} isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        {(onClose) => (
          <>
            {title && (
              <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
            )}
            <ModalBody>{body}</ModalBody>
            <ModalFooter>{actions}</ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
