import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ChatInterface, NewEventInterface } from "../interfaces";
import { io } from "socket.io-client";
const apiUrl = process.env.REACT_APP_API_URL;
export interface ChatContextInterface {
  chats: ChatInterface[];
  selectedChat: ChatInterface | null;
  sendMessage: (message: NewEventInterface) => void;
  setSelectedChat: any;
  replyMessage: (message: NewEventInterface) => void;
}
export const ChatContext = createContext<ChatContextInterface | undefined>(
  undefined
);
export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [chats, setChats] = useState<ChatInterface[]>([]);
  const [selectedChat, setSelectedChat] = useState<ChatInterface | null>(null);
  const sendMessage = (message: NewEventInterface) => {};
  useEffect(() => {
    const socket = io(apiUrl + "realtime-chat");
    socket.on("connect", () => {
      console.log("Connected to socket.io server");
    });

    socket.on("incomingEvent", (event: NewEventInterface) => {
      if (event.sender.providerId != event.instance.providerId) {
        event.event.isDeleted = event.event.isDeleted ?? false;
        setChats((prevChat) => {
          const chatIndex = prevChat.findIndex(
            (c) => c.consumer.providerId === event.sender.providerId
          );

          if (chatIndex !== -1) {
            // Actualizar un chat existente
            const updatedChat = [...prevChat];
            updatedChat[chatIndex].events.push(event.event);
            return updatedChat;
          } else {
            // Agregar un nuevo chat
            return [
              ...prevChat,
              {
                consumer: event.sender,
                instance: event.instance,
                events: [event.event],
              },
            ];
          }
        });
      }
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from socket.io server");
    });

    return () => {
      socket.disconnect();
    };
  }, []);
  const replyMessage = (message: NewEventInterface) => {
    const socket = io(apiUrl + "realtime-chat");
    socket.emit("outgoingEvent", message);
  };
  return (
    <ChatContext.Provider
      value={{
        chats,
        sendMessage,
        selectedChat,
        setSelectedChat,
        replyMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
