import {
  ChatInterface,
  direction,
  EventType,
  Instance,
  type Event as EventInterface,
  type Sender,
} from "../../interfaces";
import { format } from "date-fns";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Input,
} from "@nextui-org/react";
import { useChatContext } from "../../context";
import { useState } from "react";
import { DefaultModal } from "../defaultModal";
interface EventComponentInterface {
  sender: Sender;
  event: EventInterface;
  instance: Instance;
}
const formatDate = (timestamp: number) => {
  timestamp = timestamp < 1e12 ? timestamp * 1000 : timestamp;
  const date = new Date(timestamp);
  const now = new Date();
  const isToday = date.toDateString() === now.toDateString();
  return isToday
    ? `Hoy ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`
    : format(date, "dd-MM-yyyy HH:mm");
};

const EventComponent = ({
  event,
  instance,
  sender,
}: EventComponentInterface) => {
  const { selectedChat, replyMessage } = useChatContext();
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const renderRelatedEvent = () => {
    const relatedComment = selectedChat?.events.find(
      (e) => e.providerId === event.comment?.parentId
    );
    return (
      relatedComment && (
        <Card className="mb-3">
          <CardHeader className="my-0 pb-1 bg-secondary-600">
            <p className="text-white text-sm">Mensaje relacionado</p>
          </CardHeader>
          <CardBody className="bg-secondary-100">
            {relatedComment?.message && (
              <p className="text-secondary-800 font-normal text-sm">
                {relatedComment?.message}
              </p>
            )}
            {relatedComment?.attachments?.map((attachment) => (
              <Image
                className="mt-3"
                key={event.providerId}
                src={attachment.url}
                alt={event.providerId}
              />
            ))}
          </CardBody>
        </Card>
      )
    );
  };
  return (
    <div
      className={`flex flex-row items-start space-x-3 my-5 ${
        event.direction === direction.Incoming ? "justify-start" : "justify-end"
      }`}
      key={event.providerId}
    >
      {event.direction === direction.Incoming && (
        <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026024d" />
      )}
      <div className="flex flex-col space-y-1">
        <Card
          className="ml px-2 py-1"
          style={{ minWidth: "15rem", maxWidth: "35rem" }}
        >
          <CardHeader className="py-2">
            <p className="text-secondary-500">
              {event.direction === direction.Incoming
                ? sender.name !== "-"
                  ? sender.name
                  : sender.providerId
                : instance.name}
            </p>
          </CardHeader>
          <CardBody>
            {renderRelatedEvent()}
            {event.message && (
              <p className="text-gray-900 font-light">{event.message}</p>
            )}
            {event.attachments?.map((attachment) => (
              <Image
                className="mt-3"
                key={event.providerId}
                src={attachment.url}
                alt={event.providerId}
              />
            ))}
          </CardBody>
          <CardFooter
            className={`flex-col ${
              event.type === EventType.Comment ? "py-0" : ""
            }`}
          >
            <div className="flex flex-row w-full text-left">
              <p className="text-xs font-light w-full text-right text-cyan-900">
                {formatDate(event.timestamp)}
              </p>
            </div>
            {event.type === EventType.Comment &&
              event.direction === direction.Incoming && (
                <Input
                  placeholder="Responder comentario"
                  className="py-2"
                  value={message}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && message === "" && message) {
                      setIsModalOpen(true);
                    }
                  }}
                  onChange={(e) => setMessage(e.target.value)}
                  endContent={
                    <section className="flex flex-column my-auto">
                      <Button
                        isIconOnly
                        aria-label="Send message"
                        disabled={!message || message === ""}
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                        variant="light"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                          />
                        </svg>
                      </Button>
                      <Button
                        isIconOnly
                        aria-label="Send message"
                        onClick={() => {
                          window.open(event.comment?.relatedUrl, "_blank");
                        }}
                        variant="light"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                          />
                        </svg>
                      </Button>
                    </section>
                  }
                />
              )}
          </CardFooter>
        </Card>
        {/* <Chip
          variant="shadow"
          classNames={{
            base: "bg-white",
            content: "",
          }}
        >
          🙈
        </Chip> */}
      </div>
      {event.direction === direction.Outgoing && (
        <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026024d" />
      )}
      <DefaultModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title="Confirmar envio de mensaje"
        size="md"
        body={message}
        actions={
          <>
            <Button
              color="secondary"
              onClick={() => {
                replyMessage({
                  sender,
                  instance,
                  event: {
                    type: EventType.Comment,
                    message,
                    timestamp: new Date().getTime(),
                    providerId: "",
                    isDeleted: false,
                    comment: {
                      parentId: event.providerId,
                      relatedUrl: event.comment!.relatedUrl,
                      relatedPostId: event.comment!.relatedPostId,
                    },
                  },
                });
                setMessage("");
                setIsModalOpen(false);
              }}
            >
              Responder comentario
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                replyMessage({
                  sender,
                  instance,
                  event: {
                    type: EventType.Message,
                    message,
                    timestamp: new Date().getTime(),
                    providerId: "",
                    isDeleted: false,
                    comment: {
                      parentId: event.providerId,
                      relatedUrl: event.comment!.relatedUrl,
                      relatedPostId: event.comment!.relatedPostId,
                    },
                  },
                });
                setMessage("");
                setIsModalOpen(false);
              }}
            >
              Responder por privado
            </Button>
          </>
        }
      />
    </div>
  );
};
export default EventComponent;
