import { useJwt } from "react-jwt";
import { useAuthContext, useInstanceContext } from "../../context";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { metaLoginInterface } from "../../interfaces";
import { Button, Input } from "@nextui-org/react";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { InstancesList } from "../instances/instancesList";
export const AdminDashboard = () => {
  const { user, setUser } = useAuthContext();
  const { getUserInstances } = useInstanceContext();
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const { decodedToken: admin, isExpired } = useJwt<metaLoginInterface>(
    user!.access_token!
  );
  useEffect(() => {
    if (isExpired) {
      setUser(undefined);
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      navigate("/login");
    }
  }, [isExpired, navigate, setUser]);
  const searchInstances = () => {
    if (search.length > 0) {
      getUserInstances(search);
    }
  };
  const closeSession = () => {
    setUser(undefined);
    window.FB.logout();
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    navigate("/");
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-default-100">
      <Button
        onClick={closeSession}
        style={{ position: "absolute", top: "0", right: "2rem" }}
        className="mt-4 bg-red-900 hover:bg-red-400 text-white hover:text-white"
        fullWidth={false}
      >
        Cerrar sesión
      </Button>
      <div className="py-10 px-8 rounded-2xl flex flex-col justify-center items-center bg-gradient-to-tr from-primary to-secondary text-white shadow-lg">
        <div className="text-lg text-white">
          Hola {admin?.name}, bienvenido a CYT.
        </div>
        <div className="text-lg font-light text-sm text-white my-3">
          Por favor introduce el ID del cliente para buscarlo.
        </div>
        <Input
          label="Buscar cliente"
          isClearable
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          radius="lg"
          classNames={{
            label: "text-black/50 dark:text-white/90",
            input: [
              "bg-transparent",
              "text-black/90 dark:text-white/90",
              "placeholder:text-default-700/50 dark:placeholder:text-white/60",
            ],
            innerWrapper: "bg-transparent",
          }}
          placeholder="Ingresa el ID del cliente..."
          startContent={
            <SearchIcon
              height="1em"
              width="1em"
              className="text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0"
            />
          }
        />
        <Button
          onClick={searchInstances}
          className="mt-4 bg-purple-50 hover:bg-purple-900 text-purple-900 hover:text-white"
          fullWidth={true}
        >
          Buscar
        </Button>
        <InstancesList />
      </div>
    </div>
  );
};
