"use client";

import { Avatar, Badge, Card, CardBody, Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";

import { Sender } from "../../interfaces";
import { useChatContext } from "../../context";

type ChatUserModel = {
  user: Sender | null;
  lastMessage?: string;
};
const newMessageAnimation =
  "animate-pulse font-bold pt-1 transition ease-in-out";
const messageClass = "font-normal pt-1 transition ease-in-out";

const ChatUserComponent: React.FC<ChatUserModel> = ({
  user,
  lastMessage,
}: ChatUserModel) => {
  const [lastMessageAnimation, setLastMessageAnimation] =
    useState(messageClass);
  const { selectedChat, setSelectedChat, chats } = useChatContext();
  useEffect(() => {
    setLastMessageAnimation(newMessageAnimation);
    setTimeout(() => {
      setLastMessageAnimation(messageClass);
    }, 2000);
  }, [lastMessage]);
  // const getVerifyColor = () => {
  //   return user?.isVerified
  //     ? user?.providerId === selectedChat?.user.providerId
  //       ? "white"
  //       : "green"
  //     : user?.providerId === selectedChat?.user.providerId
  //     ? "gray"
  //     : "gray";
  // };
  return (
    <Card
      isBlurred
      isPressable
      shadow="sm"
      key={user!.providerId}
      className={"w-full border-none rounded-small mb-3"}
      style={{
        backgroundColor:
          user?.providerId === selectedChat?.consumer.providerId
            ? "black"
            : "white",
      }}
      onPress={(e) => {
        const chat = chats.find(
          (c) => c.consumer.providerId === user?.providerId
        );
        setSelectedChat(chat);
      }}
    >
      <CardBody>
        <div className="flex gap-3 items-stretch bg-red">
          <Avatar
            alt={user?.name ?? user?.providerId}
            className="flex-shrink-0"
            size="lg"
            src={"https://i.pravatar.cc/150?u=a042581f4e29026024d"}
          />
          <div className="flex flex-col items-start">
            <div className="flex items-start justify-content-center">
              <span
                className={
                  "text-base " +
                  (user?.providerId === selectedChat?.consumer.providerId
                    ? "text-white"
                    : "text-black")
                }
              >
                {user?.name && user.name !== "-"
                  ? user.name.length > 30
                    ? user.name.slice(0, 30) + "..."
                    : user.name
                  : user?.providerId}
              </span>
            </div>
            <div className="flex justify-between content-between mt-2 w-full">
              {/* <Tooltip content="Instagram">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  color={
                    user?.providerId === selectedChat?.user.providerId
                      ? "white"
                      : "#F77737"
                  }
                  stroke="currentColor"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="w-5 h-5"
                >
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37zM17.5 6.5h.01"></path>
                </svg>
              </Tooltip> */}
              {/* <Tooltip
                content={user?.isVerified ? "Verificado" : "No Verificado"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                  color={getVerifyColor()}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              </Tooltip> */}
              {/* <Tooltip content="Seguidores">
                <Badge content={user?.followerCount} size="sm" color="primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill={
                      user?.providerId === selectedChat?.user.providerId
                        ? "white"
                        : "#F77737"
                    }
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="none"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                    />
                  </svg>
                </Badge>
              </Tooltip> */}
              <Tooltip content="Mensajes">
                {user?.providerId === selectedChat?.consumer.providerId ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                    color="white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                    />
                  </svg>
                ) : (
                  <Badge content=" " size="sm" color="success">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                      color="black"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                      />
                    </svg>
                  </Badge>
                )}
              </Tooltip>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
export default ChatUserComponent;
